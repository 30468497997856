import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { OrganizationService, PublicOrganization } from '@state/organization';
import { Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })
export class PublicOrganizationResolver {
  constructor(
    private organizationService: OrganizationService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<PublicOrganization> {
    let urlSlug = route.params.organization_slug as string;
    return this.organizationService.getAndStorePublicOrganization$(urlSlug);
  }
}
