import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { AuthService } from '@app/shared/services';
import { UserQuery, UserService } from '@state/user';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class OrganizationLoginRedirectGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private userQuery: UserQuery,
    private userService: UserService
  ) {}

  canActivate(): boolean {
    let loggedIn = this.authService.loggedIn();

    if (environment.runtimeEnv !== 'production') return true;

    if (loggedIn) {
      if (!this.userQuery.getValue().hasFetchedUser) this.userService.setUserFromAuth();
      void this.router.navigate(['/dashboard']);

      return false;
    }

    return true;
  }
}
