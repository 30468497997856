import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FlowMaterialModule } from '@app/flow-material.module';
import { MessageBoxModule } from '@component/message-box';

import { ResetPasswordComponent } from './reset-password.component';

@NgModule({
  declarations: [ResetPasswordComponent],
  imports: [CommonModule, FlowMaterialModule, MatSnackBarModule, MessageBoxModule, ReactiveFormsModule]
})
export class ResetPasswordModule {}
