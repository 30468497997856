import { Injectable } from '@angular/core';
import { createStore, select, setProp, withProps } from '@ngneat/elf';
import { PublicOrganization } from './organization.model';

interface OrganizationProps {
  organization: PublicOrganization
}

const initialState: OrganizationProps = {
  organization: undefined
};

@Injectable({ providedIn: 'root' })
export class OrganizationRepository {
  private _store$ = createStore({ name: 'organization' }, withProps<OrganizationProps>(initialState));
  publicOrganization$ = this._store$.pipe(select(({ organization }) => organization));

  getPublicOrganization(): PublicOrganization {
    return this._store$.getValue().organization;
  }

  setPublicOrganization(organization: PublicOrganization): void {
    this._store$.update(setProp('organization', organization));
  }
}

