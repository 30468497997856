import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { ID } from '@datorama/akita';
import { EntityTeamMember } from '@state/entities';

import { OrganizationStore, PortfolioReport } from './organization.store';
import { PublicOrganization } from './organization.model';
import { OrganizationRepository } from './organization.repository';

@Injectable({ providedIn: 'root' })
export class OrganizationService {
  constructor(
    private http: HttpClient,
    private organizationRepository: OrganizationRepository,
    private organizationStore: OrganizationStore
  ) {}

  get$(id: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/v1/organizations/${id}`);
  }

  getUsers$(id: ID): Observable<EntityTeamMember[]> {
    return this.http.get<EntityTeamMember[]>(`${environment.apiUrl}/v1/organizations/${id}/users`);
  }

  getPortfolioReport$(organization_id: ID, contact_id: ID): Observable<PortfolioReport> {
    let params = { contact_id };
    return this.http.get<PortfolioReport>(
      `${environment.apiUrl}/v1/organizations/${organization_id}/reports/portfolio`,
      { params }
    );
  }

  getPortfolioReportCSV$(organization_id: ID, contact_id: ID): Observable<Blob> {
    let params = { contact_id };
    return this.http.get(`${environment.apiUrl}/v1/organizations/${organization_id}/reports/portfolio_csv`, {
      params,
      responseType: 'blob'
    });
  }

  getAndStoreUsers$(id: ID): Observable<EntityTeamMember[]> {
    return this.getUsers$(id).pipe(
      tap({
        next: (users) => {
          this.organizationStore.update({ users });
        }
      })
    );
  }

  getAndStorePortfolioReport$(organization_id: ID, contact_id: ID): Observable<PortfolioReport> {
    return this.getPortfolioReport$(organization_id, contact_id).pipe(
      tap({
        next: (portfolioReport) => {
          this.organizationStore.update({ portfolioReport });
        }
      })
    );
  }

  removeUser$({ organization_id, user_id }: { organization_id: ID; user_id: ID }): Observable<null> {
    let url = `${environment.apiUrl}/v1/organizations/${organization_id}/users/${user_id}`;
    return this.http.delete<null>(url);
  }

  reset(): void {
    this.organizationStore.reset();
  }

  getPublicInfo$(url_slug: string): Observable<PublicOrganization> {
    let url = `${environment.apiUrl}/v1/organizations/${url_slug}/public_info`;
    let options: object = {};
    return this.http.get(url, options) as Observable<PublicOrganization>;
  }

  getAndStorePublicOrganization$(url_slug: string): Observable<PublicOrganization> {
    return this.getPublicInfo$(url_slug).pipe(tap({
      next: (organization) => this.organizationRepository.setPublicOrganization(organization)
    }));
  }
}
